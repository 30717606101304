import React from 'react'
import caragabungsatu from '../images/product.png'
import caragabungdua from '../images/daftar-revise.png'
import buttonbelisekarang from '../images/electronic store logo.png'
import buttondaftarsekarang from '../images/button daftar sekarang.png'
import ReactGA from 'react-ga4'


function CaraBergabung() {
    const handleClick = (button) => {
        ReactGA.event({
            category: 'type',
            action: 'Click Beli',
            label: button,
        });
    };
    const handleClick2 = (button) => {
        ReactGA.event({
            category: 'type',
            action: 'Click Daftar',
            label: button,
        });
    };
    return (
    <div id='caragabung'>
        <div className='s-heading'>
        <h1>CARA GABUNG</h1>
        </div>
        <div id='hadiah'>
            <div className='hadiah-model'>
                <img src={caragabungsatu} alt='hadiah-image' width="100%" ></img>
            </div>
            <div className='hadiah-text'>
                <h1>1. Beli Produk POLYTRON</h1>
                <h2>Belanja produk Polytron minimum Rp 1,5 Juta di semua channel Polytron Official Store</h2>
               <img src={buttonbelisekarang} alt='button-beli' width='50%' height='auto' ></img> 
            </div>
        </div>
        <div id='daftar'>
            <div className='hadiah-model'>
                <img src={caragabungdua} alt='hadiah-image' width="100%" ></img>
            </div>
            <div className='hadiah-text'>
                <h1>2. Daftar Via Google Form</h1>
                <h2>Daftarkan data diri dan produk Polytron yang Anda beli untuk mendapatkan kupon undian dengan mengisi Google Form pada link di bawah ini</h2>
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSeR0uvRSmVLuOj-sbHk7xeioGqjrASH83qy5OQmGMwTpRmE5A/viewform" onClick={() => handleClick2("Daftar Sekarang")}><img src={buttondaftarsekarang} alt='button-beli' width='200' height="100"></img> </a> 
            </div>
        </div>
    </div>
  )
}

export default CaraBergabung